<template>
  <div class="content">
    <Header title="充值中心" />

    <div class="logo-box">
      <div class="logo">
        <img src="@/assets/customer/logo.png" alt="">
      </div>
      <div class="title">五味楼充值中心</div>
    </div>

    <div class="recharge-box">
      <div class="title">充值方案</div>
      <div class="moneys">
        <div v-for="(v, k) in moneys" :key="k"  :class="{
          'item': true,
          'on': money === v
        }" @click="onChoose(k)">{{v}}元</div>
      </div>
    </div>

    <div class="sub-btn-box">
      <div class="sub-btn" @click="onRecharge">立即支付</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import Header from './Header'

export default defineComponent({
  components: {
    Header
  },
  setup () {
    const money = ref(500)
    const moneys = [500, 800, 1200]

    const onChoose = index => {
      money.value = moneys[index]
    }

    const onRecharge = () => {

    }

    return {
      moneys,
      money,
      onChoose,
      onRecharge
    }
  }
})
</script>

<style lang="less" scoped>
.content{
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #ffffff;
  .logo-box{
    padding-top: 0.5rem;
    .logo{
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      box-shadow: 0 0 0.2rem rgba(153, 153, 153, 0.4);
      margin: 0 auto;
      img{
        display: block;
        width: 100%;
      }
    }
    .title{
      text-align: center;
      font-size: 0.3rem;
      color: #333;
      line-height: 0.75rem;
    }
  }

  .recharge-box{
    padding: 0.6rem 0.2rem;
    .title{
      font-size: 0.3rem;
      color: #333;
      line-height: 0.7rem;
      padding-left: 0.2rem;
    }

    .moneys{
      display: flex;
      padding-top: 0.2rem;
      .item{
        width: 2rem;
        height: 0.8rem;
        border: 1px solid #333333;
        border-radius: 0.15rem;
        line-height: 0.8rem;
        text-align: center;
        margin: 0 0.2rem 0.2rem;
        &.on{
          border-color: #ceb175;
          background: #ceb175;
          color: #fff;
        }
      }
    }
  }

  .sub-btn-box{
    position: fixed;
    bottom: 0.7rem;
    left: 0;
    width: 100%;
    padding: 0.4rem;
    .sub-btn{
      background: #ceb175;
      border-radius: 0.1rem;
      color: #fff;
      font-size: 0.3rem;
      line-height: 0.75rem;
      height: 0.75rem;
      text-align: center;
    }
  }
}
</style>
